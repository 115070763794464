import React, { useEffect, useState, useRef } from "react";
import styles from "./ArshadesValidationPage.module.css";
import app from "../data/base";
import { logStorageOut } from "../data/utils";
import { ACTIVE_BASE_URL_DASH } from "../root";

function ARShadesValidationPage() {
  const [token, setToken] = useState('');
  const iframeRef = useRef(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("csToken");
    setToken(storedToken);
  }, []);

  // Logs out user on event
  const logOut = () => {
    logStorageOut();
    app.auth().signOut();
  };

  // Listens to messages from the flutter environment
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.isLogout != null) {
        logOut();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Listen for localStorage changes
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "csToken" && event.oldValue !== event.newValue) {
        logOut();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      className={styles.frameValidation}
      src={`${ACTIVE_BASE_URL_DASH}/?csToken=${token}`}
      title="ARShades Validation"
      sandbox="allow-popups allow-same-origin allow-scripts"
    ></iframe>
  );
}

export default ARShadesValidationPage;