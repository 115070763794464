import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import { db } from "../data/base";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import { BsCode } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import styles from "./CardBrandPage.module.css";
import ColumnSubTab from "../component/modelViewer/ColumnSubTab";
import ModalIframe from "../component/modelViewer/ModalIframe";
import BackDrop from "../component/modelViewer/BackDrop";
import SearchComponent from "../component/Search";
import { ListAllBrands } from "../services/home";

function CardBrandPageRender() {
  const brands = [];

  const [glasses, setGlasses] = useState([]);
  const [model, setModel] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedModelIndex, setSelectedModelIndex] = useState(null);
  const [modelsBrandSelected, setModelsBandSelected] = useState([]);
  const [modalIframe, setModalIframe] = useState(false);
  const [glassesIdList, setGlassesIdList] = useState([]);
  const [indexModelExportCode, setIndexModelExportCode] = useState();
  const [brandIdSelected, setBrandIdSelected] = useState();
  const [variantDepth, setVariantDept] = useState(0);

  const { selectedBrand } = useSelector((state) => state);
  const rxBrands = useSelector((state) => state.config.listaBrand);

  const history = useHistory();

  const handleOnChangeBrandId = async () => {
    const listGlasses = [];
    const listIdsGlasses = [];
    const brandId = selectedBrand?.id || "all";
    if (brandId) {
      const visualizzatoreRef = await db.collection("Visualizzatori3D").get();
      visualizzatoreRef.docs.map(async (doc) => {
        const data = doc.data();
        if (
          data.brand === brandId ||
          (brandId === "all" && rxBrands.includes(data.brand))
        ) {
          if (data.id) {
            const glassesRef = await db
              .collection("Visualizzatori3D")
              .doc(data.id)
              .collection("Glasses")
              .get();

            glassesRef.docs.map((g) => {
              listGlasses.push({
                ...g.data(),
                brandId: data.id,
                glassID: g.id,
              });
              listIdsGlasses.push(g.id);
            });
            setGlasses(listGlasses);
            setGlassesIdList(listIdsGlasses);
          }
        }
      });
    }
  };

  useEffect(() => {
    handleOnChangeBrandId();
  }, [selectedBrand?.id]);

  const isModelView = useMemo(() => {
    return selectedModelIndex === null || selectedModelIndex === "undefined";
  }, [selectedModelIndex]);

  const filteredModelAndVariants = useMemo(
    () =>
      [...(isModelView ? model : modelsBrandSelected)].filter((glass) => {
        // console.log("glass", glass.nomeOcchiale )
        return glass.nomeOcchiale
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());
      }),
    [isModelView, model, modelsBrandSelected, searchQuery]
  );

  const handleOnGlassChange = async () => {
    const modelsRef = await db.collection("Modello").get();
    const models = [];
    if (glasses) {
      glasses.map((g) => {
        modelsRef.docs.map((m) => {
          const data = m.data();

          if (g.initial_model === m.id) {
            // console.log("Trovato initial", g.initial_model, m.id)

            models.push({ ...data, brandId: g.brandId, glassID: g.glassID });
          }
        });
      });
    }
    setModel(models);
  };

  useEffect(() => {
    handleOnGlassChange();
  }, [glasses]);

  const handleOnSelectModelIndex = async () => {
    
    
    const selectedModel = model[selectedModelIndex];
    // console.log("SELECTED",selectedModel);
    
    const models = [];
    const modelsId = [];
    if (typeof  selectedModel !== 'undefined'){
      // console.log("SELECTED",selectedModel);
      const modelsRef = await db.collection("Modello").get();
      modelsRef.docs.map((m) => {
        const data = m.data();
        // console.log("DATA",data);

        if(typeof data.loadingId !== 'undefined'){
          // console.log( "DATA Loading", data.loadingId)
          const glassesIDCode = data.loadingId.substring(0, 5);
          const selGlassesCode = selectedModel.loadingId.substring(0,5);
       
        if (glassesIDCode === selGlassesCode) {
          // console.log("TROVATO")
          models.push({
            ...data,
            brandId: selectedModel?.brandId,
            glassID: selectedModel?.glassID,
            id: m.id
          });
          modelsId.push(m.id);
        }
      }
      });
    }
   
    setModelsBandSelected([...models]);
  };


  // const handleOnSelectModelIndex = async () => {
  //   const selectedModel = model[selectedModelIndex];
  //   const modelsRef = await db.collection("Modello").get();
  //   const models = [];
  //   const modelsId = [];
  //   modelsRef.docs.map((m) => {
  //     const data = m.data();
  //     if (data.nomeOcchiale === selectedModel?.nomeOcchiale) {
  //       models.push({
  //         ...data,
  //         brandId: selectedModel.brandId,
  //         glassID: selectedModel.glassID,
  //       });
  //       modelsId.push(m.id);
  //     }
  //   });
  //   setModelsBandSelected([...models]);
  // };

  
  

  useEffect(() => {
    // console.log("Selected Model Index")
    handleOnSelectModelIndex();
  }, [selectedModelIndex]);

  function modelPageHandler(i) {
    // console.log("index",i);
    if (variantDepth === 0) {
      setSelectedModelIndex(i);
      setIndexModelExportCode(i);
      setVariantDept(variantDepth + 1);
    }
  }

  function modelPageHandlerVariant(i){
    const modelUrl = encodeURIComponent(filteredModelAndVariants[i].id);
    // Usa history.push se stai usando react-router-dom
    history.push(`/modelViewerRender/${modelUrl}`);
  }

  function handleExportCode(i, m) {
    if (m.stato !== "Incompleto") {
      setModalIframe(!modalIframe);
      setIndexModelExportCode(i);
    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  function handleTextArea() {}

  function copyHandler(type) {
    let copyText = null;
    if (type === "single") {
      copyText = document.getElementById("code-iframe-single");
    } else {
      copyText = document.getElementById("code-iframe");
    }
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast.success("Text copied to your clipboard");
  }

  function handleGeneteLink(i, m) {
    if (m.stato !== "Incompleto") {
      setBrandIdSelected(m?.brandId);
      setIndexModelExportCode(i);
      // console.log(variantDepth)
      // console.log("GLASSES", m)

      if(variantDepth == 1){
        window.open(
          `${process.env.PUBLIC_URL}/Arshades3d/${m?.brandId}/glasses/${m.glassID}/variant/${m.id}`,
          "_blank"
        );
      }else{
        window.open(
          `${process.env.PUBLIC_URL}/Arshades3ds/${m?.brandId}/glasses/${m.glassID}`,
          "_blank"
        );
      }
     
    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  function handleReturnOnGlasses(){
    // console.log("VARIANT DEPTH",variantDepth)
    setSelectedModelIndex(null)
    setVariantDept(variantDepth - 1);
  }

  function handleEditARView(i, m) {
    if (m.stato !== "Incompleto") {
      setBrandIdSelected(m?.brandId);
      history.push(`/editARShadesViewer/${m?.brandId}/glasses/${m.glassID}`);
    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  let iframeString = `<iframe id="spaarkly-container" src="https://staging.arshades.spaarkly.it/Arshades3ds/${brandIdSelected}/glasses/${glassesIdList[indexModelExportCode]}"
  allow="xr-spatial-tracking" > </iframe>
  <style>
  #spaarkly-container{ 
    height: 900px;
    width: 100%; 
  } 
  @media screen and (max-width: 992px){ 
    #spaarkly-container{ 
      height: 650px; 
      width: 100%;
    }
  }
  </style>`;

  const Iframe = (i) => (
    <>
      {modalIframe && (
        <ModalIframe>
          <div className="modal-iframe">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div>
                <p className="modal-iframe-heading">IFrame Code</p>
              </div>
              <div
                style={{
                  position: "relative",
                  top: "30px",
                  right: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setModalIframe(!modalIframe)}
              >
                <RxCross2 size={20} color="black" />
              </div>
            </div>
            <div className="iframe-container-wrapper">
              <div className="iframe-container">
                <textarea
                  id="code-iframe"
                  type="text"
                  rows="10"
                  value={iframeString}
                  onChange={() => handleTextArea(i)}
                />
              </div>
            </div>
            <div className="button-container">
              <Button onClick={() => copyHandler("multi")}>
                <FaCopy className="copy-icon" />
                <span>Copy</span>
              </Button>
            </div>
          </div>
        </ModalIframe>
      )}
      <BackDrop onCancel={() => setModalIframe(!modalIframe)} grey />
    </>
  );

  return (
    <>
      <div className="search-container">
        <SearchComponent
          placeholder={`Search ${isModelView ? "Model" : "Variant"}`}
          onHandleSubmit={setSearchQuery}
        />
      </div>
      {isModelView ? (
        <>
        
          <div className="container-heading-wrapper">
            <p className="page-heading-text">Select Models For Render</p>
          </div>
          <div className={styles.flexGrid}>
            {filteredModelAndVariants?.map((m, i) => {
              if (m.stato?.toLowerCase() !== "incompleto") {
                return (
                  <div className={styles["row-element"]} key={i}>
                    <div className={styles["model"]}>
                      <div
                        className={`${styles["model-image"]} model-container`}
                      >
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span className={styles["label"]}>
                            {m.nomeOcchiale}
                          </span>
                        </div>
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span
                            className={`${styles["model-state"]} ${
                              m.stato === "Incompleto"
                                ? "incomplete-modal-status"
                                : m.stato === "In Pubblicazione" || m.stato === "Pubblicato"
                                ? "published-modal-status"
                                : "ready-modal-status"
                            }`}
                          >
                            {m.stato}
                          </span>
                        </div>
                        <div
                          className={styles["model-img"]}
                          style={{
                            backgroundImage: `url(${m.poster})`,
                          }}
                          onClick={() => modelPageHandler(i)}
                        ></div>
                      </div>
                    </div>
                    {/* <ColumnSubTab>
                      <BsCode
                        onClick={() => handleExportCode(i, m)}
                        className="action-icons"
                      />

                      <AiOutlineEye
                        onClick={() => handleGeneteLink(i, m)}
                        className="action-icons"
                      />
                      <FiEdit
                        className="action-icons"
                        onClick={() => handleEditARView(i, m)}
                      />
                    </ColumnSubTab> */}
                    {modalIframe && Iframe(i)}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      ) : (
        <>
          <div className="container-heading-wrapper">
            <MdOutlineArrowBackIosNew
              onClick={() => handleReturnOnGlasses()}
              className="back-icon"
            />
            <p className="page-heading-text">Select Variant For Render</p>
          </div>
          <div className={styles.flexGrid}>
            {filteredModelAndVariants?.map((m, i) => {
              if (m.stato?.toLowerCase() !== "incompleto") {
                return (
                  <div className={styles["row-element"]} key={i}>
                    <div className={styles["model"]}>
                      <div
                        className={`${styles["model-image"]} model-container`}
                      >
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandlerVariant(i)}
                        >
                          <span className={styles["label"]}>
                            {m.nomeOcchiale}
                          </span>
                        </div>
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandlerVariant(i)}
                        >
                          <span
                            className={`${styles["model-state"]} ${
                              m.stato === "Incompleto"
                                ? "incomplete-modal-status"
                                : m.stato === "In Pubblicazione"
                                ? "published-modal-status"
                                : "ready-modal-status"
                            }`}
                          >
                            {m.stato}
                          </span>
                        </div>
                        <div
                          className={styles["model-img"]}
                          style={{
                            backgroundImage: `url(${m.poster})`,
                          }}
                          onClick={() => modelPageHandlerVariant(i)}
                        ></div>
                      </div>
                    </div>
                    {/* <ColumnSubTab>
                      <BsCode
                        onClick={() => handleExportCode(i, m)}
                        className="action-icons"
                      />

                      <AiOutlineEye
                        onClick={() => handleGeneteLink(i, m)}
                        className="action-icons"
                      />
                      <FiEdit
                        className="action-icons"
                        onClick={() => handleEditARView(i, m)}
                      />
                    </ColumnSubTab> */}
                    {/* {modalIframe && Iframe(i)} */}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}
    </>
  );
}

export default CardBrandPageRender;
