import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({

  // Staging
  // apiKey: "AIzaSyA0Hra5OfP6tWVVSpElQWpoqYXnHJwzePc",
  // authDomain: "arshadesstaging.firebaseapp.com",
  // databaseURL: "https://arshadesstaging-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "arshadesstaging",
  // storageBucket: "arshadesstaging.appspot.com",
  // messagingSenderId: "251516419347",
  // appId: "1:251516419347:web:98a76f24b00739efd26ee6",
  // measurementId: "G-9PTM5X8CGH"

  apiKey: "AIzaSyCDQNqwJw25FReWScxp2NjMSNUF_NBBDPQ",
  authDomain: "arshades-7e18a.firebaseapp.com",
  databaseURL: "https://arshades-7e18a.firebaseio.com",
  projectId: "arshades-7e18a",
  storageBucket: "arshades-7e18a.appspot.com",
  messagingSenderId: "1096373281553",
  appId: "1:1096373281553:web:12b6349a72b7be3600a2b0",
  measurementId: "G-562Q4WEHJY"
});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();
export const storage = firebase.storage();
export default app;