import React, { useState, useEffect, useRef } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineUpload, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import { brandViewerActions } from "../../redux/brandViewer/brandViewer";
import { db } from "../../data/base";
import ARShades3dsViewer from "./ARShades3dsViewer";
import { uploadImage } from "../../services/edit3dViewer";

const EditArshades3dsViewer = () => {
  const project = useSelector((state) => state.brandViewer);

  const [bgColor, setBgColor] = useState("#ffffff");
  const [bgImage, setBgImage] = useState("");
  const [enableBgImage, setEnableBgImage] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [cameraControl, setCameraControl] = useState(false);
  const [noBackground, setNoBackground] = useState(false);
  const [zoomDisabled, setZoomDisabled] = useState(false);
  const [lightExposure, setLightExposure] = useState(0);
  const [shadowIntensity, setShadowIntensity] = useState(0);
  const [shadowSmooth, setShadowSmooth] = useState(0);
  const [cameraTheta, setCameraTheta] = useState("0");
  const [cameraPhi, setCameraPhi] = useState("0");
  const [cameraRadius, setCameraRadius] = useState("0");
  const [hdrEnvFile, setHdrEnvFile] = useState("");
  const [fieldOfView, setFieldOfView] = useState(0);
  const [menuPosition, setMenuPosition] = useState("top");
  const [applyChanges, setApplyChanges] = useState(0);
  const [arIconFile, setArIconFile] = useState("");
  const [arEnabled, setArEnabled] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const currentVariant = useRef("");
  const listVariant = useRef([]);
  const history = useHistory();
  const params = useParams();
  const [userRoleCanEdit, setUserRoleCanEdit] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    if (project?.length && !isInitialized) {
      if (project[0]?.cameraOrbit) {
        const [theta, phi, radius] = project[0]?.cameraOrbit?.split(" ");
        setCameraTheta(theta || "0");
        setCameraPhi(phi || "0");
        setCameraRadius(radius || "0");
      }
      setCameraControl(!!project[0]?.cameraControls);
      setBgColor(project[0]?.background_color || "#ffffff");
      setZoomDisabled(!!project[0]?.disableZoom);
      setLightExposure(project[0]?.lightExposition || 0);
      setShadowSmooth(project[0]?.shadowSmooth || 0);
      setShadowIntensity(project[0]?.shadowIntensity || 0);
      setFieldOfView(project[0]?.fieldOfView || 0);
      setIsInitialized(true);
      setMenuPosition(project[0]?.menuPosition || "top");
      setEnableBgImage(!!project[0]?.background_boolean_image);
    }
  }, [project, isInitialized]);

  useEffect(async () => {
    const clientiRef = await db
      .collection("Profile")
      .doc(localStorage.getItem("email")?.trim())
      .get();

    const data = clientiRef.data();
    if (data?.role === "Guest") {
      setUserRoleCanEdit(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBgColorChange = (e) => {
    const value = e.target.value || "#ffffff";
    setBgColor(value);

    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        background_color: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleTransparentBackground = (e) => {
    const value = e.target.checked || false;
    setNoBackground(value);

    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        transparent: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleBgImageChange = (e) => {
    if (project[0]?.background_image) {
      const updateAbleProject = [...project];
      const updatedArray = [
        {
          ...updateAbleProject[0],
          background_image: "",
        },
        ...updateAbleProject.slice(1),
      ];
      return setTimeout(
        () =>
          dispatch(brandViewerActions.updateFirstIndexProject(updatedArray)),
        500
      );
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setBgImage(event.target.result);
    };
    reader.readAsDataURL(file);

    uploadImage(
      `glasses_image/${params?.TokenBrand}/${params?.TokenGlasses}/${currentVariant.current}}`,
      file
    )
      .then((downloadURL) => {
        toast.success("Background Image Uploaded.");
        const updateAbleProject = project;
        const updatedArray = [
          {
            ...updateAbleProject[0],
            background_image: downloadURL,
          },
          ...updateAbleProject.slice(1),
        ];
        dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleCameraControlChange = (e) => {
    const value = e.target.checked || false;
    setCameraControl(value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        cameraControls: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleZoomDisabledChange = (e) => {
    const value = e.target.checked || false;
    setZoomDisabled(value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        disableZoom: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleLightExposureChange = (e) => {
    const value = e.target.value || 0;
    setLightExposure(value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        lightExposition: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleShadowIntensityChange = (e) => {
    const value = e.target.value || 0;
    setShadowIntensity(value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        shadowIntensity: value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleShadowSmoothChange = (e) => {
    setShadowSmooth(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        shadowSmooth: e.target.value,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleCameraThetaChange = (e) => {
    setCameraTheta(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        cameraOrbit: `${e.target.value}deg ${cameraPhi}deg ${cameraRadius}deg`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleCameraPhiChange = (e) => {
    setCameraPhi(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        cameraOrbit: `${cameraTheta}deg ${e.target.value}deg  ${cameraRadius}deg`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleCameraRadiusChange = (e) => {
    setCameraRadius(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        cameraOrbit: `${cameraTheta}deg ${cameraPhi}deg  ${e.target.value}m`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleHdrEnvFileChange = (e) => {
    if (project[0]?.hdr) {
      const updateAbleProject = [...project];
      const updatedArray = [
        {
          ...updateAbleProject[0],
          hdr: "",
        },
        ...updateAbleProject.slice(1),
      ];
      return setTimeout(
        () =>
          dispatch(brandViewerActions.updateFirstIndexProject(updatedArray)),
        500
      );
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setHdrEnvFile(event.target.result);
    };
    reader.readAsDataURL(file);

    uploadImage(
      `immagini/hdr_environment/${[...Array(10)]
        .map((i) => (~~(Math.random() * 36)).toString(36))
        .join("")}`,
      file
    )
      .then((downloadURL) => {
        const updateAbleProject = project;
        const updatedArray = [
          {
            ...updateAbleProject[0],
            hdr: downloadURL,
          },
          ...updateAbleProject.slice(1),
        ];
        dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
        toast.success("HDR Environment Uploaded.");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleFieldOfViewChange = (e) => {
    setFieldOfView(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        fieldOfView: `${e.target.value}deg`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleMenuPositionChange = (e) => {
    setMenuPosition(e.target.value);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        menu_position: `${e.target.value}`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleApplyChanges = (e) => setApplyChanges(Number(e.target.value));

  const handleMenuTypeChange = (e) => {
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        menu_type: `${e.target.value}`,
      },
      ...updateAbleProject.slice(1),
    ];

    // Dispatch the action to update the project
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));

    // Scroll to the top if the selected value is "plane"
    if (e.target.value === "plane") {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (project[0]?.menu_type === "plane") {
      window.scrollTo(0, 0);
    }
  }, [project]);

  // Durante il rendering
  console.log('Current project state:', project[0]?.menu_type);


  const handleArIconFileChange = (e) => {
    if (project[0]?.ar_icon) {
      const updateAbleProject = [...project];
      const updatedArray = [
        {
          ...updateAbleProject[0],
          ar_icon: "",
        },
        ...updateAbleProject.slice(1),
      ];
      return setTimeout(
        () =>
          dispatch(brandViewerActions.updateFirstIndexProject(updatedArray)),
        500
      );
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setArIconFile(event.target.result);
    };
    reader.readAsDataURL(file);

    uploadImage(`immagini/ar-icon`, file)
      .then((downloadURL) => {
        toast.success("AR Icon Uploaded.");
        const updateAbleProject = project;
        const updatedArray = [
          {
            ...updateAbleProject[0],
            ar_icon: downloadURL,
          },
          ...updateAbleProject.slice(1),
        ];
        dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleEnableBackgroundImageChange = (e) => {
    setEnableBgImage(e.target.checked);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        background_boolean_image: `${e.target.checked}`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const handleArEnabledChange = (e) => {
    setArEnabled(e.target.checked);
    const updateAbleProject = project;
    const updatedArray = [
      {
        ...updateAbleProject[0],
        ar: `${e.target.checked}`,
      },
      ...updateAbleProject.slice(1),
    ];
    dispatch(brandViewerActions.updateFirstIndexProject(updatedArray));
  };

  const resetOtherVaraints = async () => {
    try {
      const promises = listVariant.current.map((v) =>
        db
          .collection("Visualizzatori3D")
          .doc(params.TokenBrand)
          .collection("Glasses")
          ?.doc(params?.TokenGlasses)
          .collection("variants")
          .doc(v)
          .update({ shouldApply: false })
      );

      await Promise.all(promises);
      // console.log("Completed");
    } catch (error) {
      // console.log("Error Reseting All Variants", error);
    }
  };

  const resetAllModels = async () => {
    try {
      await db
        .collection("Visualizzatori3D")
        .doc(params.TokenBrand)
        .collection("Glasses")
        ?.doc("ARTemplate")
        .update({ shouldApply: false });
    } catch (error) {
      // console.log("Error Reseting All Models", error);
    }
  };

  const handleApplyTemplate = async () => {
    if (userRoleCanEdit) {


      const brand = params.TokenBrand;
      try {
        if (applyChanges === 0) {
          //Brand Level
          await resetAllModels();
          await await resetOtherVaraints();
          await db.collection("Visualizzatori3D").doc(brand).set(project[0]);
        } else if (applyChanges === 1) {
          //Models Level
          await resetOtherVaraints();
          await db
            .collection("Visualizzatori3D")
            .doc(brand)
            .collection("Glasses")
            ?.doc(params?.TokenGlasses)
            .collection("template")
            ?.doc("ARTemplate")
            .set({ ...project[0], shouldApply: true });
        } else {
          //Variants Level
          await db
            .collection("Visualizzatori3D")
            .doc(brand)
            .collection("Glasses")
            ?.doc(params?.TokenGlasses)
            .collection("variants")
            .doc(currentVariant.current)
            .set({ ...project[0], shouldApply: true });
        }

        toast.success("Changes Saved.");
      } catch (error) {
        toast.error(error);
        console.error("Error writing data to Firestore: ", error);
      }
    }

  };

  const handleExportTemplate = () => {
    const jsonString = JSON.stringify(project[0]);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "modelData.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const jsonData = JSON.parse(reader.result);
        dispatch(brandViewerActions.updateFirstIndexProject([jsonData]));
      } catch (error) {
        console.error("Failed to parse JSON data:", error);
      }
    };

    reader.readAsText(file);
  };

  const handleImportTemplate = () => {
    document.getElementById("jsonFileInput").click();
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="edit-vr-top-container">
      <div style={{ width: "100%" }}>
        <div
          className="container-heading-wrapper page-title"
          onClick={handleGoBack}
          style={{ marginLeft: "20px" }}
        >
          <MdOutlineArrowBackIosNew className="back-icon" />
          <p className="page-heading-text">Template Editor</p>
        </div>


        <div className="d-flex justify-content-center ar-view-wrapper">
          <ARShades3dsViewer
            setCurrentVariant={(inital) => (currentVariant.current = inital)}
            _setListVariant={(list) => (listVariant.current = list)}
            isEditModeOn
            containerWidth={`${width < 1000 ? width * 0.9 : width * 0.5}px`}
            containerHeight={"500px"}
          />
        </div>
      </div>
      <div className="edit-right-side-bar">
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Background</p>
        </div>
        <div>
          <span style={{ display: "flex" }}>
            <p style={{ color: "white", position: "relative", right: "15px" }}>
              Colour
            </p>
            <p style={{ color: "white", position: "relative", left: "20px" }}>
              Background Image
            </p>
          </span>
          <div style={{ display: "flex", columnGap: "10px" }}>
            <div className="color-picker-container-copy">
              <input
                disabled={noBackground}
                type="color"
                value={bgColor}
                onChange={handleBgColorChange}
                className="color-picker-input"
                id="image"
              />
              <label
                htmlFor="image"
                style={{
                  cursor: "pointer",
                  position: "relative",
                  top: "7px",
                  right: "7px",
                }}
              >
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: bgColor,
                    borderRadius: "50%",
                    display: "inline-block",
                    justifyContent: "center",
                  }}
                />

                <AiOutlineEdit size={20} color="black" />
              </label>
            </div>

            <div className="color-picker-container-copy">
              <div className="ar-icon-container">
                {project[0]?.background_image ? (
                  <>
                    <button
                      style={{
                        border: "none",
                      }}
                      id="deleteBg"
                      onClick={handleBgImageChange}
                    />
                    <img
                      src={project[0]?.background_image}
                      alt="background Image"
                      width={30}
                      height={30}
                      style={{
                        borderRadius: "5px",
                        position: "relative",
                        top: "4px",
                      }}
                    />
                  </>
                ) : (
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg, .hdr, image/vnd.radiance"
                    onChange={handleBgImageChange}
                    style={{ display: "none" }}
                    id="bgImageInput"
                  />
                )}
                <label htmlFor="bgImageInput">
                  {project[0]?.background_image ? (
                    <label htmlFor="deleteBg">
                      <AiOutlineDelete
                        color="black"
                        size={25}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "4px",
                        }}
                      />
                    </label>
                  ) : (
                    <label htmlFor="bgImageInput">
                      <AiOutlineUpload
                        color="black"
                        size={25}
                        style={{
                          cursor: "pointer",
                          margin: "0 auto",
                          position: "relative",
                          top: "4px",
                        }}
                      />
                    </label>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_enable_background_image_label">
            Enable Background Image
          </p>
          <label className="switch" style={{ marginTop: "10px" }}>
            <input
              type="checkbox"
              checked={enableBgImage}
              onChange={handleEnableBackgroundImageChange}
            />
            <span className="slider"></span>
          </label>
        </div>

        <div className="edit-item-container">
          <p className="edit_ar_enable_background_image_label">
            Remove Background Color
          </p>
          <label className="switch" style={{ marginTop: "10px" }}>
            <input
              type="checkbox"
              checked={noBackground}
              onChange={handleTransparentBackground}
            />
            <span className="slider"></span>
          </label>
        </div>

        <div className="edit-item-container">
          <p className="edit_ar_view_label">Camera Control</p>
          <label className="switch">
            <input
              type="checkbox"
              checked={cameraControl}
              onChange={handleCameraControlChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Disable Zoom</p>
          <label className="switch">
            <input
              type="checkbox"
              checked={zoomDisabled}
              onChange={handleZoomDisabledChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Light Exposure</p>
        </div>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={1}
            step={0.1}
            max={5}
            value={lightExposure}
            onChange={handleLightExposureChange}
          />
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Shadow Intensity</p>
        </div>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={1}
            step={0.1}
            max={5}
            value={shadowIntensity}
            onChange={handleShadowIntensityChange}
          />
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Shadow Smooth</p>
        </div>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={0}
            max={1}
            step={0.1}
            value={shadowSmooth}
            onChange={handleShadowSmoothChange}
          />
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Camera Orbit</p>
        </div>
        <label className="edit_ar_view_sub_label">Theta</label>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={0}
            max={360}
            value={cameraTheta}
            onChange={handleCameraThetaChange}
          />
        </div>
        <label className="edit_ar_view_sub_label">Phi</label>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={0}
            max={360}
            value={cameraPhi}
            onChange={handleCameraPhiChange}
          />
        </div>
        <label className="edit_ar_view_sub_label">Radius</label>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={0}
            step={0.1}
            max={1}
            value={cameraRadius}
            onChange={handleCameraRadiusChange}
          />
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">
            {project[0]?.hdr ? "Delete HDR" : "HDR Environment"}
          </p>

          {project[0]?.hdr ? (
            <button
              style={{
                border: "none",
              }}
              id="deleteHDR"
              onClick={handleHdrEnvFileChange}
            ></button>
          ) : (
            <input
              type="file"
              accept="image/jpeg, image/png, image/jpg, .hdr, image/vnd.radiance"
              onChange={handleHdrEnvFileChange}
              style={{ display: "none" }}
              id="hdrImageInput"
            />
          )}
        </div>
        {!project[0]?.hdr ? (
          <div>
            <label
              htmlFor="hdrImageInput"
              style={{
                borderRadius: "5px",
                backgroundColor: "transparent",
                padding: "4px",
                border: "2px solid white",
                cursor: "pointer",
              }}
            >
              <p style={{ color: "white", margin: "0 auto" }}>Choose</p>
            </label>
          </div>
        ) : (
          <label
            htmlFor="deleteHDR"
            style={{
              borderRadius: "5px",
              backgroundColor: "white",
              padding: "4px",
              cursor: "pointer",
            }}
          >
            {/* <img
              src={project[0]?.hdr}
              alt="hdr Image"
              width={30}
              height={30}
              style={{
                position: "relative",
                bottom: "2px",
                borderRadius: "5px",
              }}
            /> */}
            <AiOutlineDelete
              color="black"
              size={25}
              style={{ cursor: "pointer", position: "relative", top: "5px" }}
            />
          </label>
        )}

        <div className="edit-item-container">
          <p className="edit_ar_view_label">Field of View</p>
        </div>
        <div className="input-range-container">
          <input
            type="range"
            className="input-range"
            min={10}
            max={90}
            value={fieldOfView}
            onChange={handleFieldOfViewChange}
          />
        </div>

        <div className="edit-item-container">
          <p className="edit_ar_view_label">Menu Position</p>
        </div>
        <div className="select-wrapper">
          <select
            value={menuPosition}
            onChange={handleMenuPositionChange}
            className="select"
          // defaultValue={menuPosition || "top"}
          >
            <option value="bottom">Bottom</option>
            <option value="top">Top</option>
            <option value="left">Left</option>
            <option value="right">Right</option>
          </select>
        </div>
        <div className="select-wrapper">
          <select
            value={project[0]?.menu_type || "plane"}
            onChange={handleMenuTypeChange}
            className="select"
          >
            <option value="plane">Plane</option>
            <option value="card">Card</option>
            <option value="circle">Circle</option>
          </select>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">AR Icon</p>

          <input
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            onChange={handleArIconFileChange}
            style={{ display: "none" }}
            id="bgArInput"
          />
        </div>

        <button
          style={{
            border: "none",
          }}
          id="deleteBgArInput"
          onClick={handleArIconFileChange}
        />
        <span>
          <p style={{ color: "white", position: "relative", bottom: "25px" }}>
            Upload SVG
          </p>
        </span>
        <div style={{ display: "flex", columnGap: "10px" }}>
          <div className="ar-icon">
            <div className="ar-icon-container">
              {project[0]?.ar_icon ? (
                <div>
                  <img
                    src={project[0]?.ar_icon}
                    alt="background Image"
                    width={30}
                    height={30}
                    style={{
                      position: "relative",
                      borderRadius: "5px",
                      top: "4px",
                    }}
                  />
                </div>
              ) : null}
              {!project[0]?.ar_icon ? (
                <label htmlFor="bgArInput">
                  <AiOutlineUpload
                    color="black"
                    size={25}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </label>
              ) : (
                <label htmlFor="deleteBgArInput">
                  <AiOutlineDelete
                    color="black"
                    size={25}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "6px",
                      left: "12px",
                    }}
                  />
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Enable AR</p>
          <label className="switch">
            <input
              type="checkbox"
              checked={arEnabled}
              onChange={handleArEnabledChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="edit-item-container">
          <p className="edit_ar_view_label">Apply Changes to</p>
        </div>
        <div className="select-wrapper">
          <select
            value={applyChanges}
            onChange={handleApplyChanges}
            className="select"
          >
            <option value={0}>Brand</option>
            <option value={1}>All Models</option>
            <option value={2}>Only this Variant</option>
          </select>
        </div>

        <button onClick={handleApplyTemplate} className="edit-ar-solid-button">
          Apply Template
        </button>


        <button
          onClick={handleExportTemplate}
          className="edit-ar-outlined-button"
        >
          Export Template
        </button>
        <input
          type="file"
          id="jsonFileInput"
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
        <button
          onClick={handleImportTemplate}
          className="edit-ar-outlined-button"
        >
          Import Template
        </button>
      </div>
    </div>
  );
};

export default EditArshades3dsViewer;