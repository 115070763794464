import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import { encodePassword, storePassword, getPassword } from "../../data/utils";
import app from "../../data/base";

const LoginForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Effettua l'accesso con email e password
      await app.auth().signInWithEmailAndPassword(data.email, data.password);

      // Verifica i permessi dell'utente
      await checkUserPermissions(data.email);

      // Gestisci l'accesso (es. navigazione o aggiornamento stato)
      await props.onSubmit(data);

      // Memorizza la password se l'utente ha scelto di ricordarla
      if (rememberPassword) {
        savePassword(data.email, data.password);
      }
    } catch (error) {
      console.error("Authentication error:", error);
    } finally {
      setLoading(false);
    }
  };

  const savePassword = (email, password) => {
    const encoded = encodePassword(password);
    storePassword(email, encoded);
  };

  const handleBlur = (data) => {
    const p = getPassword(data.target.value);
    if (p !== undefined && p !== null && p !== false && p !== "null") {
      setPassword(p);
    }
  };

  const checkUserPermissions = async (email) => {
    try {
      // Converti l'email in minuscolo
      const lowerCaseEmail = email.toLowerCase();
      
      // console.log("Fetching permissions for email:", lowerCaseEmail);
      
      // Recupera il documento dell'utente dalla collezione
      const userDoc = await app.firestore().collection('Profile').doc(lowerCaseEmail).get();
      
      if (userDoc.exists) {
        const data = userDoc.data();
        const canPlaceOrders = data.canPlaceOrders ? 'true' : 'false';
        
        // console.log("canPlaceOrders value from Firestore:", data.canPlaceOrders);
        
        // Memorizza il permesso nella localStorage
        localStorage.setItem('canPlaceOrders', canPlaceOrders);
        // console.log("canPlaceOrders value saved in localStorage:", localStorage.getItem('canPlaceOrders'));
      } else {
        localStorage.setItem('canPlaceOrders', 'false');
        // console.log("User document does not exist, defaulting canPlaceOrders to false.");
      }
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      localStorage.setItem('canPlaceOrders', 'false');
    }
  };
  

  return (
    <div className="login-form-container">
      <Card className="login-card">
        <Card.Header className="card-header login-card-header">
          <img src={logo} alt="logo" className="logo" />
          <p className="card-heading">Login</p>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                onBlur={handleBlur}
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  ref={register({
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                  isInvalid={errors.password}
                />
                <div className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                  {!errors.password?.message ? (!showPassword ? <FaEyeSlash /> : <FaEye />) : null}
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
              <div className="forgot-password">
                <a className="forgot-password-anchor" href="/forgotPassword">
                  Forgot Password?
                </a>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Remember Password"
                name="rememberPassword"
                checked={rememberPassword}
                onChange={(e) => setRememberPassword(e.target.checked)}
              />
            </Form.Group>
            <Button type="submit" block className="submit-button" disabled={loading}>
              Continue
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="circle" />
    </div>
  );
};

export default LoginForm;