import React, { useState, useEffect } from "react";
import app, { db } from "../data/base";
import firebase from "firebase";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { configActions } from "../redux/config/config";
import Login from "./authUIComponents/login";
import OTP from "./authUIComponents/otp";
import man from "../assets/images/user/user.png";

const LoginForm = (props) => {
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(
    localStorage.getItem("Name") || "Elana Saint"
  );
  const [isuser, setIsUser] = useState(localStorage.getItem("isUser") || true);
  const [role, setRole] = useState(localStorage.getItem("role" || "delegato"));
  const [smsSent, setSmsSent] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [email, setEmail] = useState(null);
  const [readyToResend, setReadyToResend] = useState(true);
  const [verificationId, setVerificationId] = useState(null);
  const [refCliente, setRefCliente] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const re = await app.auth().signInWithEmailAndPassword(data.email, data.password);
      const idToken = await re.user.getIdToken();
      
      createCustomToken(idToken);
      postLogin(data.email);
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        // The user is enrolled in MFA, must be verified
        setResolver(error.resolver);
      } else {
        setTimeout(() => {
          toast.error(error?.message);
        }, 2);
      }
    }
  };

  const postLogin = async (email) => {
    setValue(man);
    setName("");
    setIsUser("true");
    setRole("");
    setEmail("");

    await db
      .collection("Profile")
      .doc(email.toString().toLowerCase())
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          setValue(man);
          setName(doc.data().nome);
          setIsUser("true");
          setRole(doc.data().role);
          setEmail(email);
          setRefCliente(doc.data().clientRef);
          // setBrandRef(doc.data().lista_brand)
          if (doc.data().role === "Admin") {
            const res = await db.collection("Brand").get();
            const lista_brands = res.docs.map((doc) => {

              return doc?.id

            });
            localStorage.setItem("brands", lista_brands);
          } else {
            let tempListBrand = doc.data().catalogsList;
            const res = await db.collection("Brand").get();
            const lista_brandsMenoRev = [];
            const lista_brands = res.docs.map((doc) => {
              
              if (!doc.data().nome_brand.includes('REV') && tempListBrand.includes(doc?.id)) {
                lista_brandsMenoRev.push(doc?.id)
                return doc?.id

              }
            });
            // console.log("MY LIST BRAND", lista_brandsMenoRev)
            localStorage.setItem("brands", lista_brandsMenoRev);
          }
          const brands = localStorage.getItem("brands");
          dispatch(configActions.setListaBrand(brands.split(",")));
        }
      });
    await writeLoginAccess();
    props.history.push(`${process.env.PUBLIC_URL}/home`);
  };

  


  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
    localStorage.setItem("isUser", isuser);
    localStorage.setItem("role", role);
    localStorage.setItem("email", email);
    localStorage.setItem("ref_cliente", refCliente);
  }, [value, name, isuser, role, email, refCliente]);

  let docRef = null;

  async function writeLoginAccess() {
    await db
      .collection("Coll_LogAccess")
      .where("account", "==", email)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          docRef = doc.ref;
        });
      });

    if (docRef) {
      db.runTransaction((transaction) => {
        return transaction?.get(docRef).then((tDoc) => {
          if (!tDoc.exists) {
            // console.log("tdoc not exists");
            return;
          }
          let logAccess = tDoc.data().listaLog;
          const date = new Date();
          let newLog = {
            data: `${date.getFullYear()}-${date.getMonth() + 1
              }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
            dispositivo: navigator.userAgent,
          };
          logAccess.push(newLog);
          transaction.update(docRef, { listaLog: logAccess });
        });
      });
    }
  }

  const handleSendSms = async () => {
    try {
      const phoneOpts = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      const id = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        new firebase.auth.RecaptchaVerifier("recaptcha-container")
      );
      setReadyToResend(false);
      setVerificationId(id);
      setSmsSent(true);
      setReadyToResend(true);
      toast.success("OTP Send Successfully");
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const handleResendSms = async () => {
    try {
      await handleSendSms();
    } catch (err) {
      console.error(err);
    }
  };

  const handleVerifyCode = async (code) => {
    const cred = new firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );

    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    const credential = await resolver.resolveSignIn(multiFactorAssertion);

    postLogin(credential?.user?.email);
  };

  const createCustomToken = async (idToken) => {
    try {

      let result = await fetch(`https://verifyandcreatetoken-xcule5rnvq-uc.a.run.app?token=${idToken}`)
      result = await result.json();
      localStorage.setItem("csToken", result.csToken);
      // console.log("token login", result.csToken);
      toast.success("Dashboard Authenticated");
    } catch (e) {
      console.error(e);
      toast.error("Dashboard Failed to Authenticated");
    }

  }
  return (
    <>
      {resolver ? (
        <OTP
          handleVerifyCode={handleVerifyCode}
          handleResendSms={handleResendSms}
          handleSendSms={handleSendSms}
          smsSent={smsSent}
          readyToResend={readyToResend}
        />
      ) : (<>
        <Login onSubmit={onSubmit} />
      </>
      )}
    </>
  );
};

export default LoginForm;