import React, {useRef, useState, useEffect } from "react";
import styles from "./ARShades3dsViewerCustom.module.css"
import lensIcon from "../../assets/icon/Polarized.svg"
import lensIcon2 from "../../assets/icon/lens2.svg"
import lensIcon3 from "../../assets/icon/lens3.svg"
import frameIcon from "../../assets/icon/montatura1.svg"
import frameIcon2 from "../../assets/icon/montatura2.svg"

import Lottie from "react-lottie-player";
import animationLottieIcon from "../../assets/lottie/iconPulse.json"
import Transition from 'react-transition-group/Transition';

function ARShades3dViewerCustomTwo() {
  const modelRef = useRef(null);
  const [isExploded, setIsExploded] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  
 
  useEffect(() => {
   

    function handleModelLoad() {
        const modelViewer = modelRef.current;
        modelViewer.animationName = 'Teardown Inverse';
        //  handleExplodedAnimation()
        setInterval(()=> {
          setIsLoaded(true)
        }, 100)
        // if (modelViewer) {
        //     modelViewer.animationName = 'ArmatureAction';
        //     const animationLenght = 4300
        //     modelViewer.play({ repetitions: 1 });
        //     setIsExploded(true);
    
        //     setTimeout(() => {
        //         modelViewer.pause();
        //         console.log(modelViewer)
        //     },4200); // Dura 4.52 secondi
        // }
    }
  
    const modelViewer = modelRef.current;

    if (modelViewer) {
        modelViewer.addEventListener('load', handleModelLoad); // Aggiungi questo listener
    }

    // Questa funzione di pulizia viene eseguita quando il componente viene smontato.
    return () => {
        if (modelViewer) {
            modelViewer.removeEventListener('load', handleModelLoad); // Rimuovi anche questo listener
        }
    };
}, []);




  function handleImplodeAnimation() {
    const modelViewer = modelRef.current;

    if (modelViewer) {
      // modelViewer.currentTime = 0;
      // modelViewer.timeScale  = -1;

      modelViewer.animationName = 'Teardown Inverse';

      const animationLenght = 3400
      modelViewer.play({ repetitions: 1 });
      setIsExploded(false);
    
    setTimeout(() => {
      modelViewer.cameraOrbit = "42.64deg 87.75deg 0.4182m"
      modelViewer.cameraTarget = "-0.09m 0.08m 0.0m";

    }, 1500);
     
    setTimeout(() => {
          modelViewer.pause();

          // modelViewer.currentTime = 1659
        }, 4000); // Dura 4.52 secondi
      }
 
}

function handleExplodedAnimation() {
  const modelViewer = modelRef.current;

  if (modelViewer) {
    // modelViewer.currentTime = 0;
    modelViewer.animationName = 'Teardown';

    // modelViewer.currentTime= 0;
    const animationLenght = 3400
    modelViewer.play({ repetitions: 1 });
    setIsExploded(true);

    setTimeout(() => {
    modelViewer.cameraOrbit="-0.3707deg 22.5deg 0.9146m";
    modelViewer.cameraTarget = "-0.09m 0.08m 0.0m";
    }, 1500);
    modelViewer.fieldOfView="26deg"
    modelViewer.timeScale  = 1;
    setTimeout(() => {
        modelViewer.pause();

      }, 4000); // Dura 4.52 secondi
    }

}



  return (
    <div className={styles['md-animation-custom']}>




      <model-viewer
        //  environment-image = "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fphoto_2023-08-31_16-48-17.jpg?alt=media&token=c1f128d0-f805-4733-bb4f-4581860e9174"
        //  skybox-image = "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fphoto_2023-08-31_16-48-17.jpg?alt=media&token=c1f128d0-f805-4733-bb4f-4581860e9174"
        //  exposure="1"
        disable-pan
         camera-controls="true"
         interaction-prompt="none"
        interpolation-decay={300}
        ref={modelRef}
        id="customModel"
        src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2FCARRERA315S_teardown_armature_9_double%20animation.glb?alt=media&token=acfe814b-db8e-40b3-a4d9-5c182f8144b1"
        alt="Un modello 3D con animazioni e informazioni"
        class={styles['md-container']}
        // camera-controls
        // camera-orbit="42.64deg 87.75deg 0.4182m" 
        // field-of-view="30deg"
        camera-target="-0.09m 0.08m 0.0m"
        camera-orbit="-0.3707deg 22.5deg 0.9146m" 
        field-of-view="26deg"
      >
         
         {isLoaded && (
           <button className={styles['md-animation-button']} onClick={isExploded ? handleImplodeAnimation : handleExplodedAnimation}>{isExploded ? 'Compose' : 'Teardown'}
           <span className={styles['play-icon']}></span>
         </button> 
         )}
       
         
    




      </model-viewer>
    </div>
  );
}

export default ARShades3dViewerCustomTwo;
