import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../data/base";
import { useSelector, useDispatch } from "react-redux";
import ModelContainerFrontEnd from "../../component/modelViewer/ModelContainerFrontEnd";
import ProjectModelViewer from "../../component/modelViewer/ProjectModelView";
import { modelGlassesActions } from "../../redux/brandViewer/modelGlasses";
import { brandViewerActions } from "../../redux/brandViewer/brandViewer";
import Password from "../../component/modelViewer/Password";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';

function ARShades3dViewer() {
  const params = useParams();

  const dispatch = useDispatch();

  const [listVariant, setListVariant] = useState([]);
  const [modelHistory, setModelHistory] = useState([]);
  const [initialTime, setInitialTime] = useState();
  const [refBrand, setRefBrand] = useState();

  const [idViewer, setIdViewer] = useState();

  const [glassesRef, setGlassesRef] = useState(null);
  const [lineRefs, setLineRefs] = useState(null);
  const [glassesSize, setGlassesSize] = useState(null);

  const [initialModelTime, setInitiaModellTime] = useState(new Date());

  const [isWebView, setIsWebView] = useState(false);

  const brand = params.TokenBrand;
  const glasses = params.TokenGlasses;
  const variant = params.TokenVariant;

  const [lock, setLock] = useState(false);

  // const [initialModel, setInitialModel] = useState();

  const project = useSelector((state) => state.brandViewer);

  const modelInView = useSelector((state) =>
    state.modelGlasses.find((model) => model.id === variant)
  );

  const [session, setSession] = useState({
    device_id: "",
    device: "",
    device_os: "",
    is_first_session: false,
    ref_catalogo: "",
    data_inizio_sessione: "",
    data_fine_user_session: "",
    glassesRefs: [],
    lista_occhiali_visualizzati: [],
    modelRefs: [],
    lineaRefs: [],
    glassesRefsDoc: null,
    total_time: 0,
    glassesSize: null,
  });

  function unlock() {
    setLock(false);
  }

  const [initialModel, setInitialModel] = useState();

  useEffect(async () => {
    // console.log("1");
    const visualizzatoreRef = await db
      .collection("Visualizzatori3D")
      .doc(brand)
      .get();
    const data = visualizzatoreRef.data();
    setIdViewer(data.id);
    dispatch(brandViewerActions.setSelectedProject(data));
    setInitialModel(params.TokenVariant);

    const date = new Date();
    setInitialTime(date);

  }, []);





  const [src, setSrc] = useState("");

  useEffect(async () => {
    const modelRef = await db.collection("Modello").doc(variant).get();
    const data = modelRef.data();
    dispatch(
      modelGlassesActions.setModel({ data: modelRef.data(), id: variant })
    );
    setSrc(data.urlGlbComplete);

    // const fileImg = await fetch(data.poster).then((r) => r.blob());
    // const sizeMb = fileImg.size / 1000000;
    // const b = sizeMb.toFixed(2);
    // const banda = parseFloat(b);

    // Attempt to get device ID using IP
    let deviceID = uuidv4(); // Fallback to a UUID if fetching IP fails

    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      deviceID = res.data.ip;
    } catch (error) {
      console.error("Failed to fetch IP address:", error);
    }

    session.device_id = deviceID;

    let sameMonthYear = false;

    const padWithZero = (number) => number.toString().padStart(2, "0");

    const date = new Date(); // Usa il tuo oggetto date
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1); // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)
    const monthDataCons = date.getMonth() + 1; // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)

    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());

    const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
    const docBrand = docRef.data().brand;
    const brandRefDoc = db.collection("Brand").doc(docBrand);
    const brandRef = await brandRefDoc.get();
    const doc = brandRef.data().data_consumption;
    const contatoriRef = await db.collection("Contatori_Brand")?.doc(doc).get();
    const dataConsumption = contatoriRef.data();
    const glassesRefDoc = db.collection("Occhiale")?.doc(glasses);
    const glassesDoc = await glassesRefDoc.get();
    // console.log(glassesDoc.data())
    const sizeRefDoc = glassesDoc.data().lista_taglie[0];

    setGlassesRef(glassesRefDoc);
    // console.log("LINEA Ref",glassesDoc.data().lineaRef)
    setLineRefs(glassesDoc.data().lineaRef);
    setGlassesSize(sizeRefDoc);
    setRefBrand(brandRefDoc);

    const userAgent = navigator.userAgent;
    let browserName;

    const isWebView = /\bwv\b/.test(userAgent);
    setIsWebView(true);

    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Apple Safari";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
    } else {
      browserName = "Unknown";
    }

    session.device_os = browserName;

    // Check if the user has visited the site before
    const isFirstSession = !Cookies.get("visited");

    if (!Cookies.get("visited")) {
      Cookies.set("visited", "true");
      // console.log(Cookies);
    }

    session.glassesSize = sizeRefDoc ? sizeRefDoc : "";
    session.is_first_session = isFirstSession;
    // console.log(isFirstSession);
    session.glassesRefsDoc = glassesDoc.id;
    session.lineaRefs.push(glassesDoc.data().lineaRef);
    session.glassesRefs.push(glassesDoc.id);
    // console.log("INIT",session);
    session.ref_catalogo = brandRefDoc;
    session.data_inizio_sessione = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    // console.log(session);
    // console.log("DATA CON",dataConsumption.bandwidth_3dviewer);
    dataConsumption.bandwidth_3dviewer.map((bandw, i) => {
      if (bandw.month === monthDataCons && bandw.year === year) {
        sameMonthYear = true;
      }
    });
    const contatori = db.collection("Contatori_Brand").doc(doc);
    if (!sameMonthYear) {
      contatori.update("bandwidth_3dviewer", [
        ...data.bandwidth_3dviewer,
        { bandwidth_month: 0, calls_number: 0, month: monthDataCons, year },
      ]);
    }
  }, []);

  const [pwd, setPwd] = useState("");

  useEffect(async () => {
    const docRef = await db.collection("Visualizzatori3D").doc(brand).get();
    const data = docRef.data();
    setLock(data.lock);
    if (data.lock) {
      setPwd(data.password);
    }
  }, []);

  const [listCreated, setListCreated] = useState(false);

  // useEffect(async () => {

  //   let sameMonthYear = false;

  //   const date = new Date();

  //   const month = date.getMonth() + 1;
  //   let monthVar = date.getMonth() + 1;
  //   if (monthVar < 10) {
  //     monthVar = `0${monthVar}`;
  //   }
  //   const year = date.getFullYear();

  //   // const doc = "1lRTwIUrqYLewPBlC7sx";
  //   const docRef = await db.collection("Visualizzatori3D").doc(brand).get();
  //   const docBrand = docRef.data().brand;
  //   const brandRef = await db.collection("Brand").doc(docBrand).get();
  //   const doc = brandRef.data().data_consumption;
  //   const contatoriRef = await db.collection("Contatori_Brand").doc(doc).get();
  //   const data = contatoriRef.data();

  //   session.brand = brandRef.data().nome_brand;

  //   data.bandwidth_3dviewer.map((bandw, i) => {
  //     if (bandw.month === month && bandw.year === year) {
  //       sameMonthYear = true;
  //     }
  //   });

  //   const contatori = await db.collection("Contatori_Brand").doc(doc);
  //   if (!sameMonthYear) {
  //     contatori.update("bandwidth_3dviewer", [
  //       ...data.bandwidth_3dviewer,
  //       { bandwidth_month: 0, calls_number: 0, month, year },
  //     ]);
  //   }
  // }, []);

  function getMeseCorrente() {
    const dataOdierna = new Date();
    const meseCorrente = dataOdierna.getMonth() + 1; // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return meseCorrente;
  }

  function getCurrentYear() {
    const dataOdierna = new Date();
    const annoCorrente = dataOdierna.getFullYear(); // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return annoCorrente;
  }

  useEffect(async () => {
    if (variant && !lock) {
      setTimeout(async () => {
        if (!modelHistory.includes(idViewer?.src)) {
          //getting size from default variant

          const variantS = await db
            .collection("Modello")
            .doc(variant)
            .get();
          const src = variantS.data().urlGlbComplete;
          const fileImg = await fetch(src).then((r) => r.blob());
          const sizeMb = fileImg.size / 1000000;
          const b = sizeMb.toFixed(2);
          const banda = parseFloat(b);

          // const doc = "1lRTwIUrqYLewPBlC7sx";
          const docRef = await db
            .collection("Visualizzatori3D")
            ?.doc(brand)
            .get();
          const docBrand = docRef.data().brand;
          const brandRef = await db.collection("Brand")?.doc(docBrand).get();
          const doc = brandRef.data()?.data_consumption;
          const contatoriRef = await db
            .collection("Contatori_Brand")
            .doc(doc)
            .get();

          const data = contatoriRef.data();
          const size = data.bandwidth_3dviewer.length - 1;
          const meseCorrente = getMeseCorrente();
          const year = getCurrentYear();

          data.bandwidth_3dviewer.map((ele, i) => {
            if (meseCorrente === ele.month && year == ele.year
            ) {
              //  console.log("MESE CORRENTE", meseCorrente)
              const bandwidth_month = ele.bandwidth_month;
              const calls_number = ele.calls_number;
              const month = ele.month;
              const year = ele.year;
              data.bandwidth_3dviewer[i] = {
                bandwidth_month: bandwidth_month + banda,
                calls_number: calls_number + 1,
                month,
                year,
              };
            }
          });
          const contatori = db.collection("Contatori_Brand").doc(doc);
          contatori.update("bandwidth_3dviewer", data.bandwidth_3dviewer);
        }

        const modelRefId = db.collection("Modello").doc(variant);
        const modelRef = await modelRefId.get();
        const modelData = modelRef.data();

        if (!listCreated) {
          const date = new Date();
          const timeDiff = Math.floor(
            (date.getTime() - initialModelTime.getTime()) / 1000
          );
          setInitiaModellTime(new Date());
          const url = modelData.urlGlbComplete;
          const blob = await fetch(url).then((r) => r.blob());
          const b = blob.size / 1000000;
          const b1 = b.toFixed(2);
          const banda = parseFloat(b1) + 0.5;
          session.modelRefs = listVariant ? listVariant : "";
          session.device = window.navigator.userAgent;
          // session.modelRefs.push(data.id);
          // console.log("LINE REF", session.lineaRefs[0])
          session.lista_occhiali_visualizzati.push({
            nome_occhiale: modelData?.nomeOcchiale ? modelData?.nomeOcchiale : "",
            lineaRef: session?.lineaRefs[0] ? session?.lineaRefs[0] : "",
            glassesRef: session?.glassesRefsDoc ? session?.glassesRefsDoc : "",
            listaModelliProvati: [
              {
                modelRef: modelRefId,
                timeStart: initialModelTime,
                totalSeconds: timeDiff,
                tagliaRef: session?.glassesSize ? session?.glassesSize : "",
              },
            ],
            timeStart: initialModelTime,
            banda_consumata: banda,
            date: initialModelTime,
            totalSeconds: timeDiff,
          });
          setListCreated(true);
        } else {
          //write durata into previous array element
          const date = new Date();

          const timeDiff = Math.floor(
            (date.getTime() - initialModelTime.getTime()) / 1000
          );

          setInitiaModellTime(new Date());

          const listModelTry =
            session.lista_occhiali_visualizzati[0].listaModelliProvati;
          let checkModelExist = false;
          let totalTime = session.total_time;
          listModelTry.map((model) => {
            // console.log(model.modelRef, modelRefId);
            if (model.modelRef.id === modelRefId.id) {
              checkModelExist = true;
              // console.log(model.totalSeconds, timeDiff);
              model = { ...model, totalSeconds: model.totalSeconds + timeDiff };
              totalTime = totalTime + model.totalSeconds;
              // console.log(model);
            }
          });
          if (!checkModelExist) {
            listModelTry.push({
              modelRef: modelRefId,
              timeStart: initialTime,
              totalSeconds: timeDiff,
              tagliaRef: session.glassesSize,
            });
            totalTime = totalTime + timeDiff;
          }

          // console.log(totalTime);

          // session.lista_occhiali_visualizzati[0].totalSeconds = totalTime;

          //create new lista_occhiali_visualizzati element
          const url = modelData.urlGlbComplete;
          const blob = await fetch(url).then((r) => r.blob());
          const b = blob.size / 1000000;
          const b1 = b.toFixed(2);
          session.total_time =
            session.lista_occhiali_visualizzati[0].totalSeconds;
          const banda =
            session.lista_occhiali_visualizzati[0].banda_consumata +
            parseFloat(b1);
          session.lista_occhiali_visualizzati[0] = {
            ...session.lista_occhiali_visualizzati[0],
            nome_occhiale: modelData.nomeOcchiale,
            lineaRef: session.lineaRefs[0],
            glassesRef: glassesRef,
            listaModelliProvati: [
              ...session.lista_occhiali_visualizzati[0].listaModelliProvati,
            ],
            timeStart: date,
            banda_consumata: banda,
            totalSeconds: 0,
          };
          // console.log(session);
        }
      }, 1000);
    }
  }, [variant, lock]);

  useEffect(() => {

    window.addEventListener(
      "beforeunload",
      handleUnload);

    if (isWebView) return;
    return () => {
      // rimuovi l'evento beforeunload quando il componente viene smontato
      window.removeEventListener("beforeunload", handleUnload);
    };



  }, [session]);


  ///SESSION///

  const [sessionRef, setSessionRef] = useState(null);
  const [totalSessionSeconds, setTotalSessionSeconds] = useState(0);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  // Imposta un timeout di 4 minuti 
  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      setIsSessionExpired(true);
    }, 240000); // 4 minuti in millisecondi

    // Pulizia del timeout quando il componente viene smontato
    return () => clearTimeout(sessionTimeout);
  }, []);

  // Aggiunge i dati della sessione al database
  useEffect(() => {
    if (session) {
      // Aggiunge i dati della sessione al database Firestore
      const sessioneRef = db.collection("Sessione_Visualizzatori3d").doc(); // Crea un nuovo documento
      sessioneRef.set(session)
        .then(() => {
          // Salva il riferimento del documento nella variabile di stato
          setSessionRef(sessioneRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
  }, [session]); // Esegue quando `session` cambia

  // Aggiorna periodicamente i tempi della sessione e dei modelli visualizzati ogni 10 secondi
  useEffect(() => {
    const padWithZero = (number) => number.toString().padStart(2, "0");
    if (sessionRef && !isSessionExpired) {
      const interval = setInterval(() => {
        // Ottieni la data e l'ora correnti
        const date = new Date();

        // Calcola il tempo trascorso dalla data di inizio della sessione
        const timeDiff = Math.floor(
          (date.getTime() - initialModelTime.getTime()) / 1000
        );

        // Calcola il tempo totale per ciascun occhiale visualizzato
        session.lista_occhiali_visualizzati.forEach((item) => {
          // Aggiorna il tempo totale per ciascun modello provato con il tempo totale della sessione
          Object.keys(item.listaModelliProvati).forEach((modelKey) => {
            const model = item.listaModelliProvati[modelKey];
            model.totalSeconds = timeDiff;
          });

          // Aggiorna il tempo totale per l'elemento corrente
          item.totalSeconds = timeDiff;
        });

        // Aggiorna il tempo totale della sessione
        session.total_time = timeDiff;

        // Formatta la data e l'ora di fine della sessione
        session.data_fine_user_session = `${date.getFullYear()}/${padWithZero(date.getMonth() + 1)}/${padWithZero(date.getDate())} ${padWithZero(date.getHours())}:${padWithZero(date.getMinutes())}:${padWithZero(date.getSeconds())}`;

        // Aggiorna i dati della sessione nel documento esistente
        sessionRef.update(session)
          .catch((error) => {
            console.error("Error updating document: ", error);
          });

        // Aggiorna lo stato del tempo totale della sessione
        setTotalSessionSeconds(timeDiff);
      }, 10000); // Esegue ogni 10 secondi

      // Pulizia: Cancella l'intervallo quando il componente viene smontato
      return () => clearInterval(interval);
    }
  }, [sessionRef, initialModelTime, isSessionExpired]);

  // Aggiorna i tempi della sessione e dei modelli visualizzati al momento della chiusura della finestra
  function handleUnload() {
    if (sessionRef) {
      // Funzione ausiliaria per formattare i numeri con due cifre
      const padWithZero = (number) => number.toString().padStart(2, "0");

      // Ottieni la data e l'ora correnti
      const date = new Date();
      const year = date.getFullYear();
      const month = padWithZero(date.getMonth() + 1);
      const day = padWithZero(date.getDate());
      const hours = padWithZero(date.getHours());
      const minutes = padWithZero(date.getMinutes());
      const seconds = padWithZero(date.getSeconds());

      // Calcola il tempo totale per ciascun occhiale visualizzato
      const timeDiff = Math.floor(
        (date.getTime() - initialModelTime.getTime()) / 1000
      );

      session.lista_occhiali_visualizzati.forEach((item) => {
        // Aggiorna il tempo totale per ciascun modello provato con il tempo totale della sessione
        Object.keys(item.listaModelliProvati).forEach((modelKey) => {
          const model = item.listaModelliProvati[modelKey];
          model.totalSeconds = timeDiff;
        });

        // Aggiorna il tempo totale per l'elemento corrente
        item.totalSeconds = timeDiff;
      });

      // Aggiorna il tempo totale della sessione
      session.total_time = timeDiff;

      // Formatta la data e l'ora di fine della sessione
      session.data_fine_user_session = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

      // Aggiorna i dati della sessione nel documento esistente
      sessionRef.update(session)
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      // Aggiorna lo stato del tempo totale della sessione
      setTotalSessionSeconds(timeDiff);
    }
  }

  // Aggiungi un listener per l'evento "beforeunload" per gestire la chiusura della finestra
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    // Pulizia: Rimuovi il listener quando il componente viene smontato
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [sessionRef, initialModelTime]);

  //////

  return (
    <div>
      {lock ? (
        <Password unlock={unlock} password={pwd} />
      ) : (
        <ModelContainerFrontEnd
          modelSelected={modelInView}
          project={project[0]}
          singleView
        >
          {!navigator.userAgent.match(/FBAV/i) && (
            <ProjectModelViewer
              singleView
              singleSrc={src}
              // initialModel={initialModel}
              project={project[0]}
              viewerPage
              paramsId={params.token}
              urlView={`https://staging.arshades.spaarkly.it/Arshades3d/${project[0]?.id}/glasses/${params.TokenGlasses}/variant/${params.TokenVariant}`}
            ></ProjectModelViewer>
          )}
          {navigator.userAgent.match(/FBAV/i) &&
            !navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (
              <div
                id={
                  project?.menu_position === "left" ||
                    project?.menu_position === "right"
                    ? "projectTwo"
                    : "project"
                }
              >
                <img
                  src={modelInView?.not_supported_image}
                  style={{ width: "100%" }}
                ></img>
                il broswer di facebook non supporta modelli 3D e realtà
                aumentata. prova con un altro broswer
              </div>
            )}
        </ModelContainerFrontEnd>
      )}
    </div>
  );
}

export default ARShades3dViewer;
